.version-info-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.file-btn {
  display: block;
  width: 120px;
}

#reportfileinp {
  display: none;
}

.file-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
