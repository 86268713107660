// Theme Gray

// Variables

$app-container-bg: #ffffff;
$app-sidebar-bg: #ffffff;
$app-header-bg: #f8f9fa;
$app-header-logo-bg: #0b1c3a;

// Content

.app-theme-gray {

  &.app-container {
    background: $app-container-bg;
  }

  .app-sidebar {
    background: $app-sidebar-bg;
    border-right: $gray-300 solid 1px;
  }

  .app-page-title {
    background: rgba(0, 0, 0, .03);
  }

  .app-footer,
  .app-header {
    background: $app-header-bg;
  }

  .app-footer {
    border-top: $gray-300 solid 1px;
  }

  .app-header {
    .app-header__logo {
      border-right: rgba(0, 0, 0, .1) solid 1px;
    }
  }

  &.fixed-header {
    .app-header__logo {
      background: $app-header-logo-bg;
    }
  }

  .card {
    border-width: 1px;
  }

  .main-card {
    box-shadow: 0 0 0 0 transparent !important;

    & > .card-body {

      & > .card-title {
        text-transform: none;
        font-size: $font-size-lg;
        font-weight: normal;
        border-bottom: $gray-300 solid 1px;
        position: relative;
        padding: 0 0 ($card-spacer-y * 1.5);
        margin: 0 0 ($card-spacer-y * 1.5);

        &::before {
          position: absolute;
          width: 40px;
          background: $primary;
          @include border-radius(30px);
          height: 5px;
          left: 0;
          bottom: -2px;
          content: "";
        }
      }

      .d-title-tabs > .card-title {
        text-transform: none;
        font-size: $font-size-lg;
        font-weight: normal;
        border-bottom: $gray-300 solid 1px;
        position: relative;
        padding: 0 0 ($card-spacer-y * 1.5);
        margin: 0 0 ($card-spacer-y * 1.5);

        &.active::before {
          position: absolute;
          width: 40px;
          background: $primary;
          @include border-radius(30px);
          height: 5px;
          left: 0;
          bottom: -2px;
          content: "";
        }
      }
    }
  }

  .app-inner-layout__sidebar {
    border-left: 0 !important;
  }

}

.d-title-tabs {
  display: flex;
  .card-title {
    border-bottom: #8499b9 solid 1px;
  }
  .card-title::before {
    background: #fff;
  }
  .d-title-tab {
    cursor: pointer;
    min-width: 200px;
    padding-right: 25px!important;
    flex-basis: 200px;
    &:nth-child(2) {
      min-width: 150px;
      flex-basis: 150px;
    }
    &:nth-child(3) {
      //flex: 1;
    }
  }
}