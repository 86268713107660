.d-training-sup-name {
  font-weight: 600;
  display: inline-flex;
  align-items: flex-start;
}

.training-stat {
  .text-focus {
    color: #0fb3ea!important;
  }
  .text-danger {
    color: #1c98c7!important
  }
}

.c-tabs-wrapper {
  display: flex;
  padding: 10px 16px 0;
}

.c-tab {
  background: transparent;
  color: #495057;
  border: 0;
  font-size: 18px;
  padding: 0 0 8px;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  &.is-active {
    border-bottom: 3px #0fb3ea solid;
  }
}

// .rbc-month-row {
//   .rbc-row-content {
//     display: flex;
//     flex-direction: row;
//     flex: 1 0;
//     overflow: hidden;
//     .rbc-row {
//       width: 100%;
//     }
//     .rbc-row:not(:first-child) {
//       position: absolute;
//     }
//     .rbc-date-cell {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }
// }

.d-training-sup-button {
  background: #F87A1D;
  color: #fff;
  padding: 5px;
  width: 200px;
  text-align: center;
  margin-bottom: 5px;
}
.d-training-sup-desc {
  color: #707070;
  font-size: 12px;
  margin: 30px 0;
}

.d-pill-bottle {
  max-width: 100%;
  margin: 15px 0;
}

.d-sup-stars {
  padding-top: 5px;
}

.d-cell-30 {
  width: 30%;
}

.d-cell-20 {
  width: 20%;
}

.d-sup-add {
  position: absolute;
  right: 10px;
  top: 10px;
}

.d-widget-wrapper {
  background: #fff;
  position: relative;
  border: 1px solid #10AEE3;
  // padding: 20px;
  margin: 15px 0;
  .d-widget-title {
    text-align: center;
  }
}
.d-widget-content {
  padding: 20px;
}

.d-sup-widget-title {
  margin-top: 20px;
}

.d-green-border {
  border-color: #B4CA19;
}
.d-widget-solid-green {
  background: rgba(180, 202, 25, 0.43);
  border: none!important;
}
.d-colored-tabs-wrapper {
  display: flex;
  padding: 30px 20px 10px 20px;
  .d-colored-tab {
    padding: 0 20px;
    color: #fff;
    opacity: 0.7;
    position: relative;
  }
  .d-colored-tab-active {
    opacity: 1;
    img {
      position: absolute;
      bottom: 0;
      left: 20px;
    }
  }
}

.d-personal-coach-text {
  padding-left: 10px;
  padding-top: 5px;
}
.d-widget-coach .d-text {
  text-align: left;
}

.d-workout-recommendations-content {
  display: flex;
  justify-content: space-between;
}

.d-workout-recommendation {
  .d-wr-bottom {
    position: relative;
    .d-wr-overlay {

    }
  }
}

.d-no-margin-top {
  margin-top: 0;
}

.d-training-selection {
  display: flex;
  .d-category-selection-options {
    width: 25%;
    display: flex;
    flex-direction: column;
  }
  .d-training-selection-options {
    width: 75%;
    border-bottom: 1px solid #01B1EA;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 21px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
  }
  .d-category-border-right {
    border-right: 1px solid #01B1EA;
  }
  .d-category-option {
    position: relative;
    cursor: pointer;
    padding: 5px 0;
    &:last-child {
      flex: 1;
    }
    .d-category-selected-img-line {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .d-category-selected-line {
    width: 30%;
    height: 1px;
    background: #01B1EA;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .d-training-option {
    padding: 5px 0;
  }
}

.d-workout-recommendations-title {
  color: #F87A1D;
  font-size: 12px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 20px;
}

.d-workout-recommendation {
  border: 1px solid #9D9FA2;
  position: relative;
  .d-wr-overlay {
    background: rgba(255, 255, 255, 0.6);
    min-height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #0F264E;
    font-weight: 600;
    font-size: 16px;
  }
  .d-wr-title-wrapper {
    display: inline-flex;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 45px;
    position: relative;
  }
  .d-wr-title {
    font-weight: 600;
  }
  .d-wr-left {
    padding-right: 20px;
  }
  .d-wr-button {
    color: #fff;
    background: #F87A1D;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 15px;
  }
}

.d-workout-actions-search {
  border: 1px solid #DEDEDF;
  border-radius: 15px;
  width: 210px;
  position: relative;
  padding-left: 10px;
  img {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  input {
    border: none!important;
    width: 170px;
    outline: none!important;
  }
}

.d-workout-actions-button {
  background: #0F264E;
  color: #fff;
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0;
  margin-right: 35px;
}

.d-workout-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-top-color: #B4CA19!important;
}

.rc-tooltip-inner  {
  background-color: #B4CA19!important;
}

.d-calendar {
  padding-top: 20px;
  .d-calendar-event {
    text-align: center;
    height: 100%;
  }
  .d-calendar-tooltip-trigger {
    height: 100%
  }
  .rbc-today {
    background-color: transparent;
  }
  .rbc-month-view {
    .d-calendar-evt-img-bottom {
      display: none;
    }
  }
  .d-calendar-evt-img-bottom {
    position: absolute;
    bottom: 5px;
    left: 5px;
    text-align: left;
    img {
      margin-right: 5px;
    }
  }
  .d-calendar-evt-img-top {
    padding: 5px;
  }
  .d-calendar-evt-title {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
  .rbc-events-container {
    .rbc-event-label {
      display: none;
    }
  }

  .rbc-calendar {
    min-height: 400px;
  }
  .rbc-time-view {
    height: 400px;
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-view,
  .rbc-month-view {
    border-color: #83CFFF;
  }
  .rbc-time-header.rbc-overflowing {
    border: none;
  }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #83CFFF;
  }
  .rbc-time-content {
    border-top: 1px solid #83CFFF;
  }
  .rbc-header {
    position: relative;
    font-weight: 400;
    padding-bottom: 8px;
    &.today {
      color: #F87A1D;
    }
    .rbc-header-top {
      height: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .rbc-toolbar .rbc-toolbar-label {
    position: absolute;
    left: 0;
    text-align: left;
    padding-left: 20px;
    color: #A8A7A7;
  }
  .rbc-btn-group:first-child {
    position: absolute;
    right: 0;
    z-index: 1000;
    button:first-child {
      display: none;
    }
    button:nth-child(2) {
      height: 0;
      min-width: 0;
      min-height: 0;
      padding: 0;
      background: #fff;
      border: none;
      &:after {
        width: 20px;
        height: 20px;
        background: #EBEEF3;
        border-radius: 10px;
        content: "\2039";
        color: #BBBBBC;
        position: absolute;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px;
        top: 0;
        left: 0;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.44);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.44);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.44);
      }
    }
    button:nth-child(3) {
      height: 0;
      min-width: 0;
      min-height: 0;
      padding: 0;
      background: #fff;
      border: none;
      &:after {
        height: 20px;
        width: 20px;
        background: #fff;
        border-radius: 10px;
        content: "›";
        color: #BBBBBC;
        position: absolute;
        font-size: 20px;
        /* line-height: 0.6; */
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px;
        top: 0;
        left: 21px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.44);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.44);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.44);
      }
    }
  }
  .rbc-btn-group:last-child {
    display: none;
  }
}

.d-calendar-actions {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1400px) {
  .d-training-sup-button {
    width: unset;
    padding: 5px 15px;
  }
}

@media (max-width:991px) {
  .d-cell-70,
  .d-cell-50,
  .d-cell-20,
  .d-cell-30,
  .d-workouts-content-main,
  .d-content-right
   {
    width: 100%;
  }
  .d-cell-flex-end {
    align-items: center;
  }
}

.d-calendar-wrapper .rbc-calendar {
  min-height: 100%!important;
  .rbc-event {
    background: transparent;
  }
}

.d-custom-date {
  z-index: 100;
  .d-custom-day-overlay {
    opacity: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all .2s ease-out;
    background: rgba(11, 28, 59, 0.78);
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.d-custom-time {

  .d-custom-day-overlay {
    opacity: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all .2s ease-out;
    background: rgba(11, 28, 59, 0.78);
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    img {
      height: 17px;
    }
  }
}

.rbc-time-gutter {
  .d-custom-time {
    .d-custom-day-overlay {
      display: none;
    }
  }
}

.d-custom-event {
  height: 100%;
}

.d-custom-event-content {
  height: 100%;
  background: #00b1ea;
  border-color: #00b1ea;
  padding: 3px;
  border-radius: 4px;
}
.d-custom-event-open {
  background: #e84716;
  border-color: #e84716;
  padding: 3px;
  border-radius: 4px;
}
.d-custom-event-confirmed {
  background: #13a561;
  border-color: #13a561;
  padding: 3px;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .d-bottle-wrapper {
    text-align: center;
  }
  .d-workout-recommendations-content {
    display: block;
    &>div {
      max-width: 198px;
      margin: 0 auto 15px auto;
    }
  }
}

@media (max-width: 600px) {
  .d-colored-tabs-wrapper {
    display: block;
    .d-colored-tab {
      margin-bottom: 10px;
    }
    padding-bottom: 30px;
  }
  .d-calendar-actions-add {
    font-size: 10px;
  }
  .d-calendar {
    .rbc-time-slot {
      font-size: 10px;
    }
    .d-calendar-evt-title {
      display: none;
    }
    .rbc-timeslot-group {
      min-height: 25px;
    }
    .d-calendar-evt-img-bottom img:last-child {
      display: none;
    }
    .rbc-calendar {
      height: 250px;
      min-height: 250px;
    }
    .d-workout-recommendations-title {
      text-align: center;
    }
  }
}

@media (max-width: 500px) {
  .d-workout-actions {
    display: none;
  }
}
