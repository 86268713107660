.d-subtitle-controls {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .d-video-button {
    height: 38px;
    margin-right: 20px;
  }
}
.css-162g8z5 {
  background-color: #e9ecef!important;
  border: 1px solid #ced4da!important;
  .css-142w6gm {
    color: #495057;
  }
}

.rst__moveHandle.d-last-open-doc {
  background: #0194c4 !important;
  border-color: #0194c4 !important;
  color: #fff;
}


.d-tree .ReactVirtualized__Grid {
  padding: 15px;
}


.d-comment-header {
  color: #ccc;
  font-size: 12px;
}
.d-comment {
  position: relative;
}
.d-comment-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .d-comment-del-btn {
    margin-right: 10px;
  }
}
.d-video-overlay {
  background: rgba(255, 255, 255, 0);
  position: relative;
}
.d-subtitles-language-select {
  min-width: 200px;
  margin-right: 20px;
}
.d-language-subtitles-form {
  padding: 15px 0;
  .divider {
    margin-top: 0;
  }
}
.d-upload-preview {
  border: #ced4da dashed 2px;
  border-radius: 0.3rem;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  &:not(:first-child) {
    margin-top: 35px;
  }
  &:first-child {
    margin-top: 30px;
  }
}
.d-subtitles-form-cell,
.d-sequences-form-cell {
  .form-group {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}
.form-group {
  position: relative;
}
.d-dropzone-sm {
  height: 38px!important;
  padding: 0 15px;
  text-overflow: ellipsis;
  .list-group-item {
    background-color: transparent;
  }
}
.training-video-form-wrapper {
  .input-group-append {
    padding-left: 15px;
  }
}

.is-required {
  position: relative;
  width: max-content;

  &::before {
    content: '*';
    position: absolute;
    top: -1px;
    right: -9px;
    color: #c20000;
  }
}

.pass-visibility-btn {
  position: absolute;
  right: 10px;
  height: 16px;
  top: 35px;
  font-size: 18px;
}

.card,
.table-responsive,
.card-body {
  overflow: visible;
}
.ReactTable * {
    overflow: visible!important;
}
.d-add-subtitles-button {
  margin-bottom: 15px;
}
.d-subtitles-language-toggle {
  width: 100%;
  border-radius: 4px;
  padding: 7px 15px;
  text-align: left;
  outline: none!important;
  &:focus {
    border-color: #6cdbfe;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
.d-subtitles-language-toggle-wrapper {
  margin-bottom: 15px;
}

.d-page-title-buttons {
  flex-direction: row!important;
}

.d-top-header-content-right div.dropdown-menu-lg,
.popover {
  z-index: 10001
}

.fade {
  transition: opacity 0.01s linear;
  -webkit-transition: opacity 0.01s linear;
}

.d-upload-form-group {
  display: flex;
  button {
    margin-left: 10px;
  }
}
.dropzone-wrapper.dropopzone-wrapper-input {
  height: 38px;
  align-items: center;
  display: flex;
  p {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .d-header-fixed {
    position: fixed;
    padding-left: 15px;
    width: calc(100% - 280px)!important;
    right: 0;
    top: 0;
    background: #fff;
    z-index: 3000;
    margin-bottom: 0!important;
    padding-bottom: 12px;
  }
  .document-page {
      padding-top: 160px;
  }
}

@media (min-width: 1200px) {
  .document-page {
    padding-top: 160px;
  }
  .documents-fixed-header {
    position: fixed;
    top: 0;
    left: 280px;
    /* height: 100px; */
    width: calc(100% - 280px);
    margin-top: 60px;
    padding: 0;
    z-index: 10000;
    background: #fff;
    border-bottom: 1px solid rgba(13, 13, 18, 0.125);
  }
  .documents-fixed-header .app-page-title {
    margin-top: 0px!important;
  }
}

.vjs-error {
  z-index: 0;
}
