.d-workouts-content-main {
  width: 70%;
  font-family: "Roboto";
}

.d-content-body {
  display: flex;
  min-height: 100%;
  width: 100%;
  justify-content: space-between;
}

.d-widget-rel {
  position: relative;
}

.d-sort-link {
  width: 90px;
  padding: 3px;
  background: #0F264E;
  color: #fff;
  display: flex;
  padding-left: 7px;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 7px;
}


.d-content-right {
  width: 30%;
  padding-right: 0!important;
  &>.d-widget:first-child {
    margin-top: 0;
  }
}

.d-tabs-wrapper {
  display: inline-flex;
  background: #01B1EA;
  border-radius: 20px;
  font-size: 12px;
}

.d-tab-sm {
  background: #01B1EA;
  color: #fff;
  padding: 5px 40px;
  border-radius: 20px;
}

.d-tab-sm-active {
  background: #fff;
  color: #01B1EA;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(163,163,163,1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(163,163,163,1);
  box-shadow: 0px 0px 5px 0px rgba(163,163,163,1);
}

.d-my-workouts {
  background: #fff;
  .rt-resizable-header-content {
    color: #151F32;
  }
  .rt-td,
  .rt-th {
    padding: 0.77rem!important;
    position: relative;
    border-right: none!important;
  }
  .rt-th {
    background: #F9FAFB!important;
    border-bottom: 1px solid #83CFFF!important;
  }
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid #83CFFF;
  }
  .ReactTable {
    border: 1px solid #83CFFF;
  }
}

.d-cell-selected {
  border-left: 2px solid #01B1EA;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.77rem;
  left: 0;
}

.d-cell-50 {
  width: 50%;
}
.d-cell-70 {
  width: 70%;
  align-items: center;
}
.d-cell-30 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.d-training-info-name {
  font-weight: 600;
}

.d-training-info-button {
  background: #F87A1D;
  color: #fff;
  padding: 5px;
  width: 200px;
  padding-left: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}
.d-training-info-desc {
  color: #707070;
  font-size: 12px;
  padding-bottom: 40px;
}
.d-text-red {
  color: #F87A1D;
  span {
    color: #000;
  }
}
.d-text-grey {
  color: #BBBBBC;
}

.d-widget-item-grey {
  color: #9D9FA2;
  font-size: 12px;
  margin-top: 20px;
}

.d-cell-33 {
  width: 33.3333%;
  font-size: 11px;
  font-weight: 600;
  color: #BBBBBC;
  white-space: nowrap;
  &.d-reg-text {
    font-weight: 400;
  }
}

.d-video {
  position: relative;
  .d-banner {
    max-width: 100%
  }
  .d-video-footnote {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    padding: 7px;
    color: #0F264E;
    font-size: 16px;
    font-weight: 600;
  }
}

.d-w-295 {
  max-width: 295px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.d-graph-wrapper .recharts-line-dots {
  display: none;
}

.d-graph-wrapper {
  background: #fff;
  position: relative;
  border: 1px solid #10AEE3;
  padding: 20px;
  margin: 15px 0;
  .d-widget-title {
    text-align: center;
  }
}
.d-graph-container {
  padding-top: 15px;
}

.d-graph-note {
  color: #BBBBBC;
  position: absolute;
  top: 15px;
  right: 15px;
  font-weight: 500;
}

.d-graph-footnote-left,
.d-graph-footnote-right {
  color: #fff;
  background: #01B1EA;
  position: absolute;
  width: 200px;
  padding: 3px 10px;
  font-size: 12px;
}

.d-graph-footnote-left {
  left: 0;
  bottom: 0;
}

.d-graph-footnote-right {
  left: 300px;
  bottom: 0;
}

.d-training-info-group {
  color: #0F264E;
  margin-top: 25px;
}
.d-training-info-stars {
  margin-top: 15px;
}

.d-pie-chart-container {
  width: 200px;
  position: relative;
}

.d-chart-info-item {
  display: flex;
}

.d-chart-info-main-text {
  padding-bottom: 5px;
  color: #0F264E;
}
.d-chart-info-sub-text {
  color: #AFAAA9;
}

.d-chart-info-bubble {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin-right: 15px;
  &.d-red {
    background: #E53F23;
  }
  &.d-blue {
    background: #1F3D87;
  }
  &.d-coral {
    background: #EAA197;
  }
  &.d-lightblue {
    background: #83CFFF;
  }
}

.d-chart-overlay {
  position: absolute;
  top: 40%;
  left: 65px;
  color: #0F264E;
  text-align: center;
}

.d-widget-title-padding {
  padding-bottom: 20px;
}

.d-cell-flex-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.d-my-workouts {
  border: 1px solid #10AEE3;
  padding: 15px 3px;
  margin-bottom: 15px;
}

.d-my-workouts-header {
  padding: 0 10px 20px 10px;
  position: relative;
  .d-widget-title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}

.d-members-show-more {
  font-size: 12px;
  color: #0F264E;
  width: 100%;
  text-align: right;
  margin-top: 50px;
}

@media (max-width:991px) {
  .d-cell-70,
  .d-cell-50,
  .d-cell-20,
  .d-cell-30,
  .d-workouts-content-main,
  .d-content-right
   {
    width: 100%;
  }
  .d-cell-flex-end {
    align-items: center;
  }
}

@media (max-width: 600px) {
  .d-tab-sm {
    padding: 5px 15px;
  }
  .d-graph-footnote {
    display: none;
  }
  .d-workouts-content-main {
    .d-cell-70.d-flex {
      display: block!important;
    }
    .d-pie-chart-container {
      width: 100%;
    }
    .d-chart-info-item {
      justify-content: center;
    }
    .d-chart-overlay {
      width: 100%;
      left: 0;
    }
  }
}
