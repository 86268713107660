.rc-line-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  position: absolute;
  height: 7px;
  overflow: hidden;
  .rc-progress-line {
    position: absolute;
    top: 0;
  }
}
